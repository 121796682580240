import React from "react"
import { Link } from "gatsby"
import "./post-link.scss"
const PostLink = ({ post }) => (
  // <div className="postLinkBox">
  <Link to={post.frontmatter.path} className="postLink">
    <div className="postLinkBox">
      <h3 className="postTitle">{post.frontmatter.title}</h3>
      <p className="postDate">{post.frontmatter.date}</p>
      <div className="tagsContainer">
        {post.frontmatter.keywords.map(keyword => (
          <span className="search-tag">{keyword}</span>
        ))}
      </div>
      <p className="postDescription">{post.frontmatter.description}</p>
    </div>
  </Link>
  // </div>
)
export default PostLink
