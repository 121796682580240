import React, { useState } from "react"
import PostLink from "../components/post-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styles from "./posts.module.scss"
import produce from "immer"
import isEmpty from "../helpers/isEmpty"
const Posts = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const keywords = new Set()
  edges.forEach(edge => {
    if (edge.node.frontmatter.keywords) {
      edge.node.frontmatter.keywords.forEach(keyword => keywords.add(keyword))
      return
    }
  })
  const [selectedTags, setSelectedTags] = useState({})
  function toggleTag(tag) {
    setSelectedTags(
      produce(selectedTags, draft => {
        if (draft.hasOwnProperty(tag)) {
          delete draft[tag]
        } else {
          draft[tag] = true
        }
      })
    )
  }
  const [searchQuery, setSearchQuery] = useState("")
  const Posts = edges
    .filter(edge => {
      let keywordMatched = false
      for (const keyword of edge.node.frontmatter.keywords) {
        if (isEmpty(selectedTags) || selectedTags.hasOwnProperty(keyword)) {
          keywordMatched = true
          break
        }
      }
      if (!keywordMatched) {
        return false
      }
      const keywords = searchQuery.split(" ")
      const regex = new RegExp(keywords.join("|"), "i")
      return (
        regex.test(edge.node.frontmatter.title) |
        regex.test(edge.node.frontmatter.description) |
        regex.test(edge.node.excerpt)
      )
    }) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout>
      <SEO
        title="Posts by James Ku"
        description="My blog posts about programming, tech, web development & other things I want to share"
        keywords={[`tech`, `programming`, `web`, `development`, `react`]}
      />
      <div className={styles.panel}>
        <div className={styles.keyWords}>
          {Array.from(keywords).map((tag, idx) => (
            <span
              role="button"
              style={{ cursor: "pointer" }}
              tabIndex={idx}
              onClick={() => toggleTag(tag)}
              onKeyDown={() => toggleTag(tag)}
              className={
                selectedTags.hasOwnProperty(tag)
                  ? `${"search-tag"} ${"activeTag"}`
                  : "search-tag"
              }
            >
              {tag}
            </span>
          ))}
        </div>
        <div>
          <input
            type="text"
            name="search"
            id="search"
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>

      <div>{Posts}</div>
    </Layout>
  )
}
export default Posts

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            description
            title
            keywords
          }
        }
      }
    }
  }
`
